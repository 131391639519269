<template>
  <v-app>
    <home-app-bar />
    <home-view />
    <whats-app />
    <home-footer />
  </v-app>
</template>

<script>
export default {
  name: 'HomeLayout',

  components: {
    HomeAppBar: () => import('@/layouts/home/NavBar'),
    HomeFooter: () => import('@/layouts/home/Footer'),
    HomeView: () => import('@/layouts/home/View'),
    WhatsApp: () => import('@/components/WhatsappBtn')
  },
}
</script>